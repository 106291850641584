// src/components/AppConfig.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/appConfig.css';

const AppConfig = () => {
  const [appConfigData, setAppConfigData] = useState([]);
  const [allVariables, setAllVariables] = useState([]);
  const [newConfig, setNewConfig] = useState({ name: '', value: '', category: '', platform: '1' });
  const [selectedPlatform, setSelectedPlatform] = useState('1');
  const [showInputFields, setShowInputFields] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [editingConfigId, setEditingConfigId] = useState(null);
  const [editingConfigValue, setEditingConfigValue] = useState('');



  useEffect(() => {
    fetchAppConfig();
    fetchAllVariables();
  }, []);

  const handleValueChange = (e) => {
    setEditingConfigValue(e.target.value);
  };

  const handleUpdateClick = async (id) => {
    try {
      const updatedConfig = { value: editingConfigValue };
      const response = await axios.put(`https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app/variables/${id}`, updatedConfig, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      console.log('Config updated:', response.data);

      // Update the item in the appConfigData array without changing the order of other items
      setAppConfigData((prevData) =>
        prevData.map(config =>
          config.id === id ? { ...config, value: editingConfigValue } : config
        )
      );
      setEditingConfigId(null);
      setEditingConfigValue('');
    } catch (error) {
      console.error('Failed to update config:', error);
    }
  };

  const handleDeleteClick = async (id) => {
    const confirmed = window.confirm('你確定要刪除這個配置嗎？');
    if (!confirmed) {
      return;
    }
  
    try {
      await axios.delete(`https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app/variables/${id}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      console.log(`Config with ID ${id} deleted`);
  
      // Remove the deleted item from the appConfigData array without changing the order of other items
      setAppConfigData((prevData) => prevData.filter(config => config.id !== id));
    } catch (error) {
      console.error('Failed to delete config:', error);
    }
  };


  const handleEditClick = (id, value) => {
    setEditingConfigId(id);
    setEditingConfigValue(value);
  };

  const fetchAppConfig = async () => {
    try {
      const response = await axios.get(
        'https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app/variables/?skip=0&limit=100',
        {
          headers: {
            Accept: 'application/json',
          },
        }
      );
      setAppConfigData(response.data);
    } catch (error) {
      console.error('Failed to fetch App config:', error);
    }
  };

  

  const fetchAllVariables = async () => {
    try {
      const response = await axios.get(
        'https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app/variables/?skip=0&limit=100',
        {
          headers: {
            Accept: 'application/json',
          },
        }
      );
      setAllVariables(response.data);
    } catch (error) {
      console.error('Failed to fetch variables:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewConfig({ ...newConfig, [name]: value });
    if (value) setValidationMessage(''); // Clear validation message if value exists
  };

  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
  };

  const handleConfirmClick = async () => {
    if (!newConfig.name || !newConfig.value || !newConfig.category) {
      setValidationMessage('名稱、數值和類別是必填項');
      return;
    }

    try {
      const response = await axios.post(
        'https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app/variables/',
        { ...newConfig, platforms: [selectedPlatform] },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      const newConfigId = response.data.id;

      await axios.post(
        `https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app/variables/add_platforms/${newConfigId}/`,
        [selectedPlatform],
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      const newConfigDetails = await axios.get(
        `https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app/variables/${newConfigId}`,
        {
          headers: {
            Accept: 'application/json',
          },
        }
      );

      setAppConfigData((prevData) => [...prevData, newConfigDetails.data]);
      setNewConfig({ name: '', value: '', category: '' });
      setShowInputFields(false);
      setSelectedPlatform('1');
      setValidationMessage('');
    } catch (error) {
      console.error('Failed to add new config:', error);
    }
  };

  const renderAppConfigTable = () => {
    if (!appConfigData || appConfigData.length === 0) {
      return <p>暫無資料</p>;
    }
  
    return (
      <div className="appconfig-table-container">
        <table className="appconfig-config-table">
          <thead>
            <tr>
              <th>名稱</th>
              <th>數值</th>
              <th>類別</th>
              <th>平台</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {appConfigData.map((config) => (
              <tr key={config.id}>
                <td>{config.name}</td>
                <td>
                  {editingConfigId === config.id ? (
                    <input
                      type="text"
                      value={editingConfigValue}
                      onChange={handleValueChange}
                    />
                  ) : (
                    config.value
                  )}
                </td>
                <td>{config.category}</td>
                <td>
                  <ul>
                    {config.platforms.map((platform) => (
                      <li 
                        key={platform.id} 
                        className={`platform-${platform.name.toLowerCase()}`}
                        style={{ color: platform.name.toLowerCase() === 'android' ? 'green' : platform.name.toLowerCase() === 'ios' ? 'yellow' : 'inherit' }}
                      >
                        {platform.name}
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="button-container">
                  {editingConfigId === config.id ? (
                    <button className="confirm-button" onClick={() => handleUpdateClick(config.id)}>更新</button>
                  ) : (
                    <>
                      <button className="edit-button" onClick={() => handleEditClick(config.id, config.value)}>編輯</button>
                      <button className="delete-button" onClick={() => handleDeleteClick(config.id)}>刪除</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  

  return (
    <div className="page-content scrollable-content">
      <h1>App 參數設定</h1>
      {appConfigData ? (
        <div>
          <div className="add-button-container">
            <button className="add-button-1" onClick={() => setShowInputFields(!showInputFields)}>+</button>
            {showInputFields && (
              <div className="input-fields">
                <input
                  type="text"
                  name="name"
                  placeholder="名稱"
                  value={newConfig.name}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                />
                <input
                  type="text"
                  name="value"
                  placeholder="數值"
                  value={newConfig.value}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                />
                <input
                  type="text"
                  name="category"
                  placeholder="類別"
                  value={newConfig.category}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                />
                <select className="custom-select" name="platform" value={selectedPlatform} onChange={handlePlatformChange}>
                  <option value="1">All</option>
                  <option value="2">Android</option>
                  <option value="3">iOS</option>
                </select>
                <button className="confirm-button" onClick={handleConfirmClick}>確定</button>
              </div>
            )}
          </div>

         
          {validationMessage && <p className="validation-message">{validationMessage}</p>}
                {renderAppConfigTable()}
              </div>
            ) : (
              <p>載入中...</p>
            )}
          </div>
        );}

export default AppConfig;

