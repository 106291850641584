// src/components/Admin.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import MeetVersion from './MeetVersion';
import AppConfig from './AppConfig';
import InternalTestUrl from './InternalTestUrl';
import '../styles/admin.css';

const Admin = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'meetVersion':
        return <MeetVersion />;
      case 'appConfig':
        return <AppConfig />;
      case 'internalTestUrl':
        return <InternalTestUrl />;
      case 'products':
        return <div className="page-content"><h1>voip推播紀錄</h1><p>這裡是產品頁面的內容。</p></div>;
      case 'settings':
        return <div className="page-content"><h1>設置</h1><p>這裡是設置頁面的內容。</p></div>;
      default:
        return <div className="page-content"><h1>歡迎來到管理員儀表板</h1><p>這裡是管理員儀表板頁面。</p></div>;
    }
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  return (
    <div className="admin-container">
      <Sidebar selectedMenuItem={selectedMenuItem} onMenuItemClick={handleMenuItemClick} />
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Admin;
