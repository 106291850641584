import React, { useState } from 'react';
import Footer from './footer';
import { Router, Route, useNavigate } from 'react-router-dom'; // 导入 Router 和 useNavigate，而不是 BrowserRouter
import './App.css';

import axios from 'axios'; 



function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (!username || !password) {
      setError('Username and password are required');
    } else if(username != null && password != null){
        getOAuthResponse();

    }
    else {
      setError('');
      console.log('Username:', username);
      console.log('Password:', password);
      navigate('/admin');
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    if (e.target.value) {
      setError('');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setError('');
    }
  };

  const submitMplusLogin = () =>{
    if(username != null && password != null ){
      getOAuthResponse();
    }
  }


  const getOAuthResponse = async () => {
    const meetingUrl = "https://mplusasps.mplusapp.com/AuthenticationServer/OAuthAuthentication";
    try {
      let bodyText = 
        JSON.stringify({
          bindingAccount: username,
          bindingPwd: password,
          // loginApName: `${oS}-${oSVer}-${appVersion}-${deviceId}-${manufator}`,
          timestamp: (Date.now() / 1000).toFixed(0).toString(),
          // language: language,
        });
      
      
      const response = await axios.post(meetingUrl, {
        bindingAccount: username,
        bindingPwd: password,
        // loginApName: `${oS}-${oSVer}-${appVersion}-${deviceId}-${manufator}`,
        timestamp: Math.floor(Date.now() / 1000).toString(),
        //    ff
      },
      
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Cache-Control': 'no-cache',
        },
        body:bodyText
      }
      
    );
    
      const statusCode = response.status;
      if(statusCode === 200){
        console.log(response);
        console.log(statusCode);
        navigate('/admin');
      }else{
          setError('An error occurred while logging in');
      }
      console.log('Response:', response.data);
  
      // navigate('/admin');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while logging in');
    }
  };


  return (
   
      <div className="App">
        <header className="App-header">
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <p>M+ login(CMS)</p>
          </div>
          <div className={`login-form ${error ? 'error' : ''}`}>
            <div className="form-group">
              <label htmlFor="username" className="label">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                className="input"
                value={username}
                onChange={(e) => { handleUsernameChange(e) }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="label">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                className="input"
                value={password}
                onChange={(e) => { handlePasswordChange(e) }}
              />
            </div>
            <button className="submit-btn" onClick={handleLogin}>Login</button>
            <p className="error-msg">{error}</p>
          </div>
          <Footer />
        </header>
      </div>
  );
}

export default App;
