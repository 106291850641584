import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // 导入 BrowserRouter 和 Routes
import Admin from './components/Admin';


ReactDOM.render(
  <Router> {/* 使用 Router 包裹整个应用程序 */}
    <Routes> {/* 使用 Routes 包裹所有的路由 */}
      <Route path="/" element={<App />} /> {/* 定义根路径的路由，渲染 App 组件 */}
      <Route path="/admin" element={<Admin />} /> {/* 定义 /admin 路径的路由，渲染 Admin 组件 */}
    </Routes>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
