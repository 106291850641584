// src/services/apiService.js
import axios from 'axios';

const API_BASE_URL = 'https://mplus-meet-client-config-service-ckluflyomq-de.a.run.app';

export const fetchAppConfig = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/variables/?skip=0&limit=100`, {
      headers: { 'Accept': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch App config:', error);
    return [];
  }
};

export const fetchMeetVersions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/configurations/?skip=0&limit=100`, {
      headers: { 'Accept': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch Meet versions:', error);
    return [];
  }
};

export const fetchMeetVersionData = async (versionId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/configurations/${versionId}`, {
      headers: { 'Accept': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch Meet version data for ${versionId}:`, error);
    return null;
  }
};

export const addVersion = async (version) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/configurations/`, { version }, {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to add version:', error);
    throw error;
  }
};

export const deleteVersion = async (versionId) => {
  try {
    await axios.delete(`${API_BASE_URL}/configurations/${versionId}`, {
      headers: { 'Accept': 'application/json' }
    });
  } catch (error) {
    console.error('Failed to delete version:', error);
    throw error;
  }
};

export const fetchAllVariables = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/variables/?skip=0&limit=100`, {
      headers: { 'Accept': 'application/json' }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch all variables:', error);
    return [];
  }
};

// src/services/apiService.js
export const fetchAppsData = async (platform) => {
  let url = '';
  if (platform === 'ios') {
    url = 'https://mplus-meet-test-api-44457575120.asia-east1.run.app/api/ios/apps';
  } else if (platform === 'android') {
    url = 'https://mplus-meet-test-api-44457575120.asia-east1.run.app/api/android/apps';
  }

  try {
    const response = await axios.get(url, {
      headers: { 'Accept': 'application/json' }
    });
    return response;
  } catch (error) {
    console.error(`Failed to fetch apps data for ${platform}:`, error);
    throw error;
  }
};

export const addVariablesToMeetVersion = async (selectedMeetVersion, variableIds) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/configurations/add_variables/${selectedMeetVersion}/`,
      variableIds,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data; // Return response if needed
  } catch (error) {
    console.error('Failed to add variables:', error);
    throw error; // Re-throw error to handle it in the calling function
  }
};
// 在 apiService.js 中
export const deleteVariablesfromMeetVersion = async (selectedMeetVersion, variableIds) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/configurations/remove_variables/${selectedMeetVersion}/`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: variableIds, // 將變數 ID 放入 data 中，這樣 DELETE 請求就可以包含數據
      }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to delete variables:', error);
    throw error;
  }
};





// 將所有函數匯出為一個對象，並作為預設匯出
export default {
  fetchAppConfig,
  fetchMeetVersions,
  fetchMeetVersionData,
  addVersion,
  deleteVersion,
  fetchAllVariables,
  addVariablesToMeetVersion,
  deleteVariablesfromMeetVersion
};

