// src/components/InternalTestUrl.js
import React, { useState } from 'react';
import { fetchAppsData } from '../services/apiService'; // 具名匯入 fetchAppsData
import '../styles/dropdown.css';

const InternalTestUrl = () => {
  const [platform, setPlatform] = useState(''); 
  const [appsData, setAppsData] = useState([]); // 用於存儲API返回的數據
  const [error, setError] = useState(null); // 用於存儲錯誤訊息

  const handlePlatformChange = async (e) => {
    const selectedPlatform = e.target.value;
    setPlatform(selectedPlatform);
    setError(null);

    if (!selectedPlatform) return;

    try {
      const response = await fetchAppsData(selectedPlatform);
      if (response.data && response.data.data) {
        setAppsData(response.data.data); // 存儲返回的數據
      } else {
        setAppsData([]);
        setError('未找到相關數據');
      }
    } catch (error) {
      setError('無法取得應用程式資料，請稍後再試');
      console.error('Failed to fetch apps data:', error);
    }
  };

  return (
    <div className="page-content">
      <h1>內部測試網址</h1>
      <div>
        <select
          id="platform-select"
          value={platform}
          onChange={handlePlatformChange}
          className="dropdown-toggle"
        >
          <option value="">請選擇平臺</option>
          <option value="ios">iOS</option>
          <option value="android">Android</option>
        </select>
      </div>

      {error && <p className="error-message">{error}</p>}

      {appsData.length > 0 ? (
        <div className="table-container">
          <table className="config-table">
            <thead>
              <tr>
                <th>版本</th>
                <th>Build</th>
                <th>文件名</th>
                <th>更新時間</th>
                <th>下載連結</th>
              </tr>
            </thead>
            <tbody>
              {appsData.map((app, index) => (
                <tr key={index}>
                  <td>{app.version}</td>
                  <td>{app.build}</td>
                 
                  <td>{app.filename}</td>
                  <td>{app.updatedTime}</td>
                  <td>
                    <a href={app.downloadUrl} target="_blank" rel="noopener noreferrer">
                      下載
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        platform && !error && <p>暫無數據。</p>
      )}
    </div>
  );
};

export default InternalTestUrl;
